<template>
  <!-- prettier-ignore -->
  <the-typography
    class="radio"
    :class="classes"
    tag="label"
    type="bodyLargeShort"
  >
    <input
      v-bind="$attrs"
      class="radio__input"
      type="radio"
      :checked="checked"
      :disabled="$props.disabled"
      @change="toggle"
    />

    <the-icon
      class="radio__icon"
      :art="iconData.art"
      :name="iconData.name"
    />

    <div class="radio__label">
      <slot />
    </div>
  </the-typography>
</template>

<script setup>
import { computed, getCurrentInstance } from 'vue'

// INIT
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  invalid: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number, Boolean, undefined],
    default: undefined,
  },
  theme: {
    type: String,
    default: 'light',
  },
})

// COMPUTED
const attrValue = computed(() => {
  return getCurrentInstance().attrs.value
})

const checked = computed(() => {
  return props.modelValue === attrValue.value
})

const classes = computed(() => {
  return {
    'radio--selected': checked.value,
    'radio--disabled': props.disabled,
    'radio--invalid': props.invalid,
    [`radio--${props.theme}`]: true,
  }
})

const iconData = computed(() => {
  return {
    art: checked.value ? 'solid' : 'light',
    name: 'circle',
  }
})

// METHODS
function toggle() {
  if (!checked.value) {
    emit('update:modelValue', attrValue.value)
  }
}
</script>

<style name="mobile" scoped>
.radio {
  cursor: pointer;
  display: inline-block;
  position: relative;
  color: var(--c-primary-neutral-1);

  .radio__icon {
    position: absolute;
    font-size: 20px;
    color: var(--c-primary-neutral-1);
  }

  .radio__input {
    pointer-events: none;
    position: absolute;
    opacity: 0;

    &:focus ~ .radio__label {
      color: var(--c-primary-color-2);
    }
  }

  .radio__label {
    padding-left: 26px;
    user-select: none;
  }

  &:hover,
  &:hover :deep(.icon) {
    color: var(--c-primary-color-2);
  }
}

.radio--selected {
  .icon {
    color: var(--c-primary-color-2);
  }
}

.radio--dark {
  .radio {
    .radio__label,
    .radio__icon {
      color: var(--c-primary-neutral-3);
    }
  }
  &.radio--selected {
    .icon {
      color: var(--c-primary-color-2);
    }
  }
}

.radio--disabled {
  opacity: 0.5;
}

.radio--invalid {
  .radio__icon {
    color: var(--c-secondary-color-1);
  }
}
</style>
