<template>
  <!-- prettier-ignore -->
  <div
    class="group"
    :class="classes"
  >
    <div
      v-for="item in $props.items"
      :key="item.value"
    >
      <form-radio
        v-model="radioValue"
        class="group__item"
        :disabled="$props.readonly && item.value !== $props.modelValue"
        :invalid="isInvalid"
        :name="$props.name"
        :theme="$props.theme"
        :value="item.value"
        @update:modelValue="emitUpdate(item.value)"
      >
        <template v-if="$props.raw">
          <span v-html="item.text" />
        </template>
        <template v-else>
          {{ $t(item.text) }}
        </template>
      </form-radio>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'

import FormRadio from '@/components/Form/Radio'

// INIT
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  manualValidation: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number, Boolean, undefined],
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  raw: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: 'light',
  },
  validate: {
    type: Object,
    default: () => {},
  },
  vertical: {
    type: Boolean,
    default: false,
  },
})

// DATA
const radioValue = ref(props.modelValue)

// COMPUTED
const classes = computed(() => {
  return {
    'group--invalid': isInvalid.value,
    'group--vertical': props.vertical,
  }
})

const isInvalid = computed(() => {
  return props.validate?.$invalid && props.validate?.$error
})

// METHODS
function emitUpdate(value) {
  if (!props.manualValidation) props.validate?.$touch()
  emit('update:modelValue', value)
}

// WATCHERS
watch(
  () => props.modelValue,
  value => {
    radioValue.value = value
  }
)
</script>

<style name="mobile" scoped>
.group {
  display: flex;
  flex-direction: row;
  column-gap: 25px;

  &__item {
    margin: 5px 0;
  }

  &--vertical {
    flex-direction: column;
  }

  &--invalid {
    .group__item {
      color: var(--c-secondary-color-1);
    }
  }
}
</style>

<style name="tablet" scoped>
@media (--sm) {
  .group__item {
    min-width: 75px;
  }
}
</style>
